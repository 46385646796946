import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';

import { ExpansionPanelDetails } from '@material-ui/core';
import api from '../api';
import General from './eventDetailPanelContent/General';
import OperationEvent from './eventDetailPanelContent/OperationEvent';
import TravelEvent from '../propertyCalendar/eventDetailPanelContent/travelEvent';
import { usePanelDispatch } from '../panel/PanelContext';
import * as panelTypes from '../panel/panelTypes';
// import { check } from 'prettier';

const EventDetailPanel = props => {
  const tzValue = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const {
    calendarEvents: calendarEvents,
    timeZone: timeZone,
    calendarAdmin: calendarAdmin,
    newEventsPermission: newEventsPermission,
    filters: filters,
  } = props;
  const panelDispatch = usePanelDispatch();

  const panelCategory = useRef();
  const panelCompleted = useRef();
  const panelDepartment = useRef();
  const panelDescription = useRef();
  const panelElement = useRef();
  const panelEnd = useRef();
  const panelProperty = useRef();
  const panelRecurring = useRef();
  const panelRecurringUntil = useRef();
  const panelStart = useRef();
  const panelTitle = useRef();
  const panelVisibility = useRef();
  const panelEventType = useRef();
  const panelTrainingMaterial = useRef();
  const panelCommunicationType = useRef();
  const panelEngagementStrategy = useRef();
  const panelTargetAudience = useRef();
  const panelApprovalStatus = useRef();
  const panelApprovalNotes = useRef();
  const panelAssociatedDates = useRef();
  const panelDetails = useRef();

  const handleOpen = info => {
    if (info.event.extendedProps['is_all_day'] == true) {
      panelStart.current = info.event.start.toLocaleString('en-US', {
        weekday: 'short',
        month: 'numeric',
        day: 'numeric',
        year: '2-digit',
        timeZone: 'UTC',
      });
      if (info.event.end) {
        const endDate = info.event.end;
        const endDateDayBefore = new Date(endDate.setDate(endDate.getDate() - 1));
        panelEnd.current = endDateDayBefore.toLocaleString('en-US', {
          weekday: 'short',
          month: 'numeric',
          day: 'numeric',
          year: '2-digit',
          timeZone: 'UTC',
        });
      } else {
        panelEnd.current = null;
      }
    } else {
      panelStart.current = info.event.start.toLocaleString('en-US', {
        weekday: 'short',
        month: 'numeric',
        day: 'numeric',
        year: '2-digit',
        hour: 'numeric',
        minute: '2-digit',
        timeZone: 'UTC',
      });
      if (info.event.end) {
        panelEnd.current = info.event.end.toLocaleString('en-US', {
          weekday: 'short',
          month: 'numeric',
          day: 'numeric',
          year: '2-digit',
          hour: 'numeric',
          minute: '2-digit',
          timeZone: 'UTC',
        });
      } else {
        panelEnd.current = null;
      }
    }
    if (info.event.extendedProps['event_series'] === 'Legacy') {
      panelCategory.current = info.event.extendedProps['category'];
      panelCompleted.current = info.event.extendedProps['is_completed'];
      panelDepartment.current = info.event.extendedProps['department'];
      panelDescription.current = info.event.extendedProps['description'];
      panelProperty.current = info.event.extendedProps['property'];
      panelRecurring.current = info.event.extendedProps['is_recurring_index'];
      panelRecurringUntil.current = info.event.extendedProps['until_date_index'];
      panelTitle.current = info.event.title;
      panelVisibility.current = info.event.extendedProps['visibility'];
      panelElement.current = (
        <General
          category={panelCategory.current}
          completed={panelCompleted.current}
          department={panelDepartment.current}
          description={panelDescription.current}
          end={panelEnd.current}
          property={panelProperty.current}
          recurring={panelRecurring.current}
          recurringUntil={panelRecurringUntil.current}
          start={panelStart.current}
          title={panelTitle.current}
          visibility={panelVisibility.current}
          tzValue={tzValue}
        />
      );
    } else if (info.event.extendedProps['event_series'] === 'Current') {
      panelDepartment.current = info.event.extendedProps['department'];
      panelTitle.current = info.event.title;
      panelVisibility.current = info.event.extendedProps['visibility'];
      panelEventType.current = info.event.extendedProps['event_type'];
      panelTrainingMaterial.current = info.event.extendedProps['training_material'];
      panelCommunicationType.current = info.event.extendedProps['communication_type'];
      panelEngagementStrategy.current = info.event.extendedProps['engagement_strategy'];
      panelTargetAudience.current = info.event.extendedProps['target_audience'];
      panelApprovalStatus.current = info.event.extendedProps['approval_status'];
      panelApprovalNotes.current = info.event.extendedProps['approval_notes'];
      panelDescription.current = info.event.extendedProps['description'];
      panelAssociatedDates.current = info.event.extendedProps['associated_dates'];
      panelElement.current = (
        <OperationEvent
          department={panelDepartment.current}
          title={panelTitle.current}
          visibility={panelVisibility.current}
          eventType={panelEventType.current}
          trainingMaterial={panelTrainingMaterial.current}
          communicationType={panelCommunicationType.current}
          engagementStrategy={panelEngagementStrategy.current}
          targetAudience={panelTargetAudience.current}
          approvalStatus={panelApprovalStatus.current}
          approvalNotes={panelApprovalNotes.current}
          description={panelDescription.current}
          associatedDates={info.event.extendedProps['associated_dates']}
          tzValue={tzValue}
        />
      );
    } else if (info.event.extendedProps['sub_category'] === 'Travel Event') {
      panelStart.current = info.event.start.toLocaleString('en-US', {
        weekday: 'short',
        month: 'numeric',
        day: 'numeric',
        year: '2-digit',
        hour: 'numeric',
        minute: '2-digit',
        timeZone: 'UTC',
      });
      panelEnd.current = info.event.end.toLocaleString('en-US', {
        weekday: 'short',
        month: 'numeric',
        day: 'numeric',
        year: '2-digit',
        hour: 'numeric',
        minute: '2-digit',
        timeZone: 'UTC',
      });
      panelDescription.current = info.event.extendedProps['description'];
      panelDetails.current = info.event.extendedProps['details'];

      panelElement.current = (
        <TravelEvent
          start={panelStart.current}
          end={panelEnd.current}
          details={panelDetails.current}
          description={panelDescription.current}
          tzValue={tzValue}
        />
      );
    }
    panelDispatch({
      type: panelTypes.OPEN,
      render: panelElement.current,
    });
  };

  const [events, setEvents] = useState(calendarEvents);
  const [filteredEvents, setFilteredEvents] = useState([]);
  const checkedEventTypes = {
    checkedGeneralCommunication: 'General Communication',
    checkedRolloutOrInitiative: 'Rollout/Initiative',
    checkedTraining: 'Training',
    checkedWebinar: 'Webinar',
    checkedMeeting: 'Meeting',
    checkedHoliday: 'Holiday',
    checkedEvent: 'Event',
    checkedOptionalOfficeHour: 'Optional Office Hour',
    checkedMisc: 'Misc',
    checkedLegacyEvent: 'Legacy Event',
    checkedTravelEvent: 'Travel Event',
  };

  const checkFilteredEvents = () => {
    const calendarFilters = filters;
    const asArray = Object.entries(calendarFilters);
    const filtered = asArray.filter(([, value]) => value === false);
    const filteredObject = Object.fromEntries(filtered);
    const filteredDepartments = Object.keys(filteredObject).map(key => checkedEventTypes[key]);
    return filteredDepartments.flat();
  };

  useEffect(() => {
    toggleEvents();
  }, [filters]);

  const toggleEvents = () => {
    let allEvents;
    if (events.length > 0 && filteredEvents.length > 0) {
      allEvents = events.concat(filteredEvents);
    } else if (events.length > 0 && filteredEvents.length === 0) {
      allEvents = events;
    } else if (events.length === 0 && filteredEvents.length > 0) {
      allEvents = filteredEvents;
    } else if (events.length === 0 && filteredEvents.length === 0) {
      allEvents = [];
    }
    toggleEventData(allEvents);
  };

  const toggleEventData = data => {
    setEvents(eventDataVisible(data));
    setFilteredEvents(eventDataFiltered(data));
  };

  const eventDataVisible = data => {
    const eventTypesToFilter = checkFilteredEvents();
    const eventsToDisplay = data?.filter(event => !eventTypesToFilter.includes(event.event_type));
    return eventsToDisplay;
  };

  const eventDataFiltered = data => {
    const eventTypesToFilter = checkFilteredEvents();
    const eventsToFilter = data?.filter(event => eventTypesToFilter.includes(event.event_type));
    return eventsToFilter;
  };

  const getMonthlyEvents = start => {
    api.calendar
      .getByDate({ monthStart: start })
      .then(({ data }) => {
        toggleEventData(data.calendar_events);
      })
      .catch(() => {});
  };

  const enabled = true;

  const addCustomButton = () => {
    const editEventsButton = {};
    if (calendarAdmin) {
      editEventsButton.newEvents = {
        text: 'Travel Events',
        click: function() {
          window.location.assign('/travel_events');
        },
      };
    }
    return editEventsButton;
  };

  const getHeaderLayout = () => {
    const calendarAdminHeader = {
      left: 'prev,next',
      center: 'title',
      right: 'editEvents dayGridMonth,timeGridWeek,timeGridDay',
    };
    const regularHeader = {
      left: 'prev,next today',
      center: 'title',
      right: 'dayGridMonth,timeGridWeek,timeGridDay',
    };
    const newEventsHeader = {
      left: 'prev,next today',
      center: 'title',
      right: 'newEvents dayGridMonth,timeGridWeek,timeGridDay',
    };
    const newEventsAndEditEventsHeader = {
      left: 'prev,next today',
      center: 'title',
      right: 'newEvents dayGridMonth,timeGridWeek,timeGridDay',
    };
    if (calendarAdmin && !newEventsPermission) {
      return calendarAdminHeader;
    } else if (newEventsPermission && !calendarAdmin) {
      return newEventsHeader;
    } else if (calendarAdmin && newEventsPermission) {
      return newEventsAndEditEventsHeader;
    } else {
      return regularHeader;
    }
  };
  return (
    <>
      <FullCalendar
        customButtons={addCustomButton()}
        datesSet={view => getMonthlyEvents(view.start)}
        dayMaxEventRows={enabled}
        eventClick={handleOpen}
        events={events}
        headerToolbar={getHeaderLayout()}
        initialView="dayGridMonth"
        nowIndicator={enabled}
        plugins={[dayGridPlugin, timeGridPlugin]}
        scrollTime="08:00:00"
        showNonCurrentDates={false}
        timeZone={timeZone}
      />
    </>
  );
};

EventDetailPanel.propTypes = {
  calendarAdmin: PropTypes.bool,
  newEventsPermission: PropTypes.bool,
  calendarEvents: PropTypes.instanceOf(Array),
  filters: PropTypes.shape({
    checkedTraining: PropTypes.bool.isRequired,
    checkedRolloutOrInitiative: PropTypes.bool.isRequired,
    checkedEvent: PropTypes.bool.isRequired,
    checkedMeeting: PropTypes.bool.isRequired,
    checkedHoliday: PropTypes.bool.isRequired,
    checkedMisc: PropTypes.bool.isRequired,
    checkedInformativeEmail: PropTypes.bool.isRequired,
    checkedMeetingWebinar: PropTypes.bool.isRequired,
    checkedBlockout: PropTypes.bool.isRequired,
    checkedLegacyEvent: PropTypes.bool.isRequired,
    checkedTravelEvent: PropTypes.bool.isRequired,
  }).isRequired,
  timeZone: PropTypes.string,
};

EventDetailPanel.defaultProps = {
  calendarAdmin: false,
  calendarEvents: [],
  timeZone: 'Central Time (US & Canada)',
  newEventsPermission: false,
};

export default EventDetailPanel;
